import React, { ReactNode } from 'react';
import Employees from './employees';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';

const Index = (): ReactNode => {
  const { user } = useAuth();
  const { replace } = useRouter();

  if (!user?.userGroup) return null;

  if (user.isVTailAdmin) {
    replace(PATHS.ADMIN_COMPANIES);
    return null;
  }

  return <Employees />;
};

export default Index;
