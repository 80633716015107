import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${getSpacing(6)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Inline = styled.div`
  display: flex;
  align-items: center;
`;

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;
