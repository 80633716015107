import React from 'react';
import { Button } from 'atoms/Button/Button';
import { AddEmployeeModal } from 'components/AddEmployeeModal/AddEmployeeModal';
import { TEST_IDS } from 'constants/testIds';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { Wrapper, Heading, Inline } from './styles';
import { useQuery } from '@apollo/client';
import { getCompany } from 'queries/company';
import { useBusinessUnit } from 'hooks/useBusinessUnit/useBusinessUnit';

export const EmployeeActions: React.FC = () => {
  const { handleModal } = useModal();
  const { companyId } = useBusinessUnit();
  useQuery(getCompany, {
    variables: { companyId },
    skip: !companyId,
  });

  return (
    <Wrapper>
      <Inline>
        <Heading>Employees</Heading>
      </Inline>
      <Button
        text="Create Employee"
        data-testid={TEST_IDS.addEmployeesButton}
        onClick={() => handleModal(<AddEmployeeModal />)}
      />
    </Wrapper>
  );
};
