import React from 'react';
import EmployeesTable from 'components/EmployeesTable';
import { ProfileDetails } from 'components/ProfileDetails/ProfileDetails';
import { EmployeeActions } from 'components/EmployeeActions/EmployeeActions';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getEmployee } from 'queries/employee';
import { withAuthRouteProtection } from 'hoc/withAuthRouteProtection/withAuthRouteProtection';
import { AdminWrapper } from '../../features/admin/components/AdminWrapper/AdminWrapper';
import { EmployeesQueryTypes } from 'components/EmployeesTable/EmployeesTable';
import { useBusinessUnit } from 'hooks/useBusinessUnit/useBusinessUnit';
import { getEmployees } from 'queries/employees';
import { refetchData } from 'features/admin/Employees/Employees';

export const Results = styled.div`
  display: flex;
`;

const ProductRepresentatives: React.FunctionComponent = () => {
  const [fetchEmployee, { loading, data }] = useLazyQuery(getEmployee);

  const { businessUnitId } = useBusinessUnit();
  const {
    data: employeesData,
    loading: employeesLoading,
    refetch: refetchEmployees,
  } = useQuery<EmployeesQueryTypes>(getEmployees, {
    skip: !businessUnitId,
    variables: {
      businessUnitId: businessUnitId,
      limit: 500,
    },
  });

  const handleGetEmployee = (employeeId: string): void => {
    fetchEmployee({
      variables: {
        employeeId,
      },
    });
  };

  const deleteEmployeeAndFetch = (id: string) => refetchData(id, refetchEmployees);

  return (
    <AdminWrapper>
      <EmployeeActions />
      <Results>
        <EmployeesTable
          getEmployee={handleGetEmployee}
          data={employeesData}
          loading={employeesLoading}
        />
        <ProfileDetails loading={loading} data={data} refetchData={deleteEmployeeAndFetch} />
      </Results>
    </AdminWrapper>
  );
};

export default withAuthRouteProtection(ProductRepresentatives, 'buAdmin');
