import React from 'react';
import Card from 'atoms/Card';
import Column from 'atoms/Column';
import Row from 'atoms/Row';
import { Loader } from 'atoms/Loader/Loader';
import { Container, Message, TableHead } from './styles';
import { TEST_IDS } from 'constants/testIds';
import UserStatusIcon from 'components/UserStatusIcon';
import { Employee } from '../../../../types/graphql';

type LastEvaluatedKey = {
  PK: string;
  SK: string;
};

export type EmployeesQueryTypes = {
  getEmployeesByBusinessUnitId: {
    items: Employee[];
    lastEvaluatedKey?: LastEvaluatedKey;
  };
};

type EmployeesTableProps = {
  getEmployee: (employeeId: string) => void;
  data?: EmployeesQueryTypes;
  loading: boolean;
};

const EmployeesTable: React.FC<EmployeesTableProps> = ({ getEmployee, loading, data }) => {
  const renderBody = () => {
    if (loading) return <Loader />;

    if (data?.getEmployeesByBusinessUnitId?.items.length === 0) {
      return (
        <Message>
          You haven't added any Employees yet. To get started click the 'Create Employee' button.
        </Message>
      );
    }

    return (
      <div>
        {data?.getEmployeesByBusinessUnitId.items
          .slice()
          .sort((a, b) => a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
          .map((employee: Employee, i) => {
            return (
              <Row onClick={() => getEmployee(employee.id)} selectable key={i}>
                <Column width={2} noMarginRight noMarginLeft>
                  <UserStatusIcon employee={employee} />
                </Column>
                <Column width={23}>{`${employee.firstName} ${employee.lastName}`}</Column>
                <Column width={40}>{employee.email}</Column>
                <Column width={35}>{employee.jobTitle}</Column>
              </Row>
            );
          })}
      </div>
    );
  };

  return (
    <Card flex={3} data-testid={TEST_IDS.employeeTable}>
      <TableHead>
        <Column width={2} noMarginRight noMarginLeft></Column>
        <Column width={23}>Name</Column>
        <Column width={40}>Email</Column>
        <Column width={35}>Job Title</Column>
      </TableHead>
      <Container data-testid={TEST_IDS.employeeTableBody}>{renderBody()}</Container>
    </Card>
  );
};

export default EmployeesTable;
